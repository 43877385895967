$light-green: #e2f6ee;
$color-dark: #243239;
$color-red: #b43f3f;

:root {
    --primary-color: #41a877;
    --light-green: #e2f6ee;
    --light-yellow: #f6f6e2;
    --color-yellow: #FFDE4D;
    --color-dark: #243239;
    --color-red: #b43f3f;
    --color-blue: #4F75FF;
    --color-orange: #E85C0D;
    --light-grey: #f8f6f5;

    --border-radius: 20px;

}