@use "node_modules/@angular/material" as mat;
// @import 'node_modules/@angular/material/theming';
@import "ngx-toastr/toastr";
@import "webflow";
@import "_variables";
@import "custom";

@include mat.core();

$md-cargoflow-primary: (
  50: #e8f6ef,
  100: #c8e9d8,
  200: #a5dbc0,
  300: #80cea7,
  400: #65c295,
  500: #49b882,
  600: #42a877,
  700: #3b9569,
  800: #36845d,
  900: #2e6348,
  // A100 : #ff95e3,
  // A200 : #ff62d5,
  // A400 : #ff2fc8,
  // A700 : #ff15c1,
  contrast:
    (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
);
$md-cargoflow-secondary: (
  50: #243239,
  100: #243239,
  200: #243239,
  300: #243239,
  400: #243239,
  500: #243239,
  600: #243239,
  700: #243239,
  800: #243239,
  900: #243239,
  // 50 : #e8f6ef,
  // 100 : #c8e9d8,
  // 200 : #a5dbc0,
  // 300 : #80cea7,
  // 400 : #65c295,
  // 500 : #49b882,
  // 600 : #42a877,
  // 700 : #3b9569,
  // 800 : #36845d,
  // 900 : #2e6348,
  // // A100 : #ff95e3,
  // // A200 : #ff62d5,
  // // A400 : #ff2fc8,
  // // A700 : #ff15c1,
  contrast:
    (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #ffffff,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
);
$md-cargoflow-accent: (
  50: #e0eaf3,
  100: #b3cbe2,
  200: #80a8cf,
  300: #4d85bb,
  400: #266bad,
  500: #00519e,
  600: #004a96,
  700: #00408c,
  800: #003782,
  900: #002770,
  A100: #9fb8ff,
  A200: #6c92ff,
  A400: #396cff,
  A700: #1f59ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-cargoflow-new-seconday: (
  dark: #243239,
);

$cargoflow-primary: mat.define-palette($md-cargoflow-primary, 600);
$cargoflow-secondary: mat.define-palette($md-cargoflow-secondary);
$cargoflow-accent: mat.define-palette($md-cargoflow-accent);
// $my-palette: mat.$indigo-palette;

// Define a light theme
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($cargoflow-primary),
      secondary: mat.define-palette($cargoflow-secondary),
      accent: mat.define-palette($cargoflow-accent),
    ),
  )
);

// Apply the dark theme by default
@include mat.core-color($light-theme);
@include mat.button-color($light-theme);
@include mat.toolbar-theme($light-theme);
@include mat.all-component-themes($light-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  @include mat.core-color($light-theme);
  @include mat.button-color($light-theme);
  @include mat.toolbar-theme($light-theme);
  @include mat.all-component-themes($light-theme);
}

html {
  scrollbar-color: var(--primary-color) $light-green;
  scrollbar-width: thin;
}

body {
  overflow: auto;
  overflow-x: hidden;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  padding: 0.5rem 0.75rem;
  color: var(--primary-color);
  font-size: 1rem;
  transition: border-bottom 0.3s ease;
}

h1 {
  font-size: 1.75rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.mat-toolbar.mat-primary {
  color: white;
}

.co-pointer {
  cursor: pointer;
}

.co-simple-list {
  list-style-type: none;
  padding-left: 0;
}

.co-unreadable-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  user-select: none;
}

// tables

.co-table {
  width: 100%;
  table {
    background: #fff;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 1px 1px 2px #ccc;
  }
  thead {
    left: -9999rem;
    position: absolute;
    top: -9999rem;
  }
  td {
    // display: block;
    margin: 0.5rem 0.25rem;
    display: flex;
    justify-content: space-between;
  }
  td:before {
    color: #888;
    content: attr(name) "";
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 300;
    margin-right: 0.5rem;
  }
  tr:nth-last-of-type(even) {
    background: rgba(56, 113, 170, 0.15);
  }

  @media (min-width: 680px) {
    table {
      border-collapse: collapse;
    }
    thead {
      left: 0;
      position: relative;
      top: 0;
    }
    th {
      color: #888;
      font-size: 0.875rem;
      font-weight: 300;
      text-align: left;
    }
    td,
    th {
      display: table-cell;
      padding: 0.75rem 1rem;
      margin: 0;
    }
    td:before {
      content: none;
    }
  }
}

.co-btn-flat-primary {
  background-color: #36845d !important;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 9px 15px;
  text-decoration: none;
}

.co-card {
  background-color: #f8f6f5;
  border: 1px solid #dcdddd;
  border-radius: 20px;
}

// NEW DESIGN
.primary-hover-animation {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-6px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &:disabled {
      transform: translateY(0);
      box-shadow: none;
    }
  }
}

::ng-deep {
  .mat-mdc-dialog-surface {
    border-radius: var(--border-radius);
  }
}

