body {
  // MAP, somehow this doesn't work in the map.scss
  .custom-marker {
    width: 30px;
    height: 46px;
    // border-radius: 50%;
    background-size: cover;
    cursor: pointer;
  }

  .driver-marker {
    background-image: url("../shared/assets/icon/marker_driver.svg");
  }

  .pickup-marker {
    background-image: url("../shared/assets/icon/marker_pickup.svg");
  }

  .delivery-marker {
    background-image: url("../shared/assets/icon/marker_delivery.svg");
  }

  a {
    color: var(--primary-color);
  }

  .mat-icon {
    display: flex;
  }

  .form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .bg-green-light {
    background-color: #e2f6ee;
  }
  .bg-yellow-light {
    background-color: #e7d37f;
  }

  // BUTTONS --------------------------------------------------------------------------------------------------------------------
  .primary-hover-animation {
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: translateY(-6px);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      &:disabled {
        transform: translateY(0);
        box-shadow: none;
      }
    }
  }

  .btn-primary {
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 4px;
    transition: all 0.3s ease-in-out;
    &:disabled {
      opacity: 0.5;
      filter: grayscale(1);
      cursor: not-allowed;
    }

    a {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }

    .mat-icon {
      height: 18px;
      width: 18px;
      font-size: 18px;
    }

    // SIZES
    &.big {
      font-size: 1.5rem;
      padding: 12px 32px;
      gap: 0.5rem;
      .mat-icon {
        height: 1.5rem;
        width: 1.5rem;
        font-size: 1.5rem;
      }
    }

    &.small-icon {
      padding: 0;
      height: 40px;
      width: 40px;
    }

    &.big-icon {
      padding: 0;
      height: 50px;
      width: 50px;
      .mat-icon {
        height: 30px;
        width: 30px;
        font-size: 1.75rem;
      }
    }

    &.mobile-text {
        span {
            display: none;
        }
        @media screen and (max-width: 767px) {
            width: auto;
            padding: 0 1rem;
            span {
                display: block;
            }
        }
    }

    // COLORS
    &.red {
      background-color: var(--color-red);
      color: white !important;
    }

    &.blue {
        background-color: var(--color-blue);
        color: white !important;
    }

    &.green,
    &.active {
      background-color: var(--primary-color);
      color: white !important;
      a {
        color: white;
      }
    }

    &.dark {
      background-color: #243239;
      color: white !important;
      a {
        color: white;
      }
    }

    &.light {
      background-color: #f8f6f5;
      color: #243239 !important;
    }

    &.warning {
      background-color: #ffb200;
      color: white !important;
    }

    &.yellow {
      background-color: #ffe76a;
      color: slategray !important;
    }

    &:active {
      transform: translateY(2px);
    }
  }
  .delete-btn {
    background-color: var(--color-dark) !important;
    color: white;
    border-radius: 10px;
    padding: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    &:disabled {
      opacity: 0.5;
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }

  .info-btn {
    background-color: transparent;
    color: var(--color-orange);
    padding: 4px;
  }
  .dark-btn {
    background-color: #243239;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    color: white !important;
    padding: 10px 20px;
  }

  .light-btn {
    background-color: #f8f6f5;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #243239 !important;
    padding: 10px 20px;
    text-decoration: none;
  }

  .green-btn,
  .active-btn {
    background-color: var(--primary-color);
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    color: white !important;
    padding: 10px 26px;
    text-decoration: none;
    cursor: pointer;
  }
  .round-icon-btn {
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    color: white;
    padding: 4px;
    height: fit-content;
    width: fit-content;
  }

  // page headers
  .page-header-container {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    h2 {
      font-size: 1.75rem;
      font-weight: 800;
      margin-top: 0.5rem;
    }
    .buttons-container {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 0.5rem;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        .material-icons {
          font-size: 2rem;
          height: 2rem;
          width: 2rem;
        }
      }
    }

    @media screen and (max-width: 767px) {
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        order: 2;
      }
      .buttons-container {
        width: 100%;
        a {
          margin-left: auto;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .driver-view-bottom {
      margin-bottom: 120px;
    }
  }

  .bottom-container-fade {
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;

    .fade-container {
        height: 60px;
        background: linear-gradient(to bottom, transparent, white);
    }

    .button-wrapper {
        background-color: white;
        padding: 1rem 1rem;
    }
  }

  // TABLES
  .table-custom {
    margin-top: 1.5rem;
    thead {
      position: sticky;
      top: 0;
      z-index: 10;

      tr {
        background-color: white;
      }

      th {
        padding-left: 0.5rem;
        border: none;
      }

      td, th {
        font-size: 18px;
        font-weight: 400;
        background-color: none;
        &:first-child {
          padding-left: 0.5rem;
        }
      }
    }
    tbody {
      tr {
        border: #fff 1rem solid;
      }
      td {
        font-size: 14px;
        vertical-align: middle;
        font-weight: 400;
        background-color: #f8f6f5 !important;
        &:first-child {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }
        &:last-child {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      }
    }
    .top-fade {
        height: 60px;
        background: linear-gradient(to top, transparent, white);
        width: 100%;
      }
      .bottom-fade {
        height: 60px;
        background: linear-gradient(to bottom, transparent, white);
        width: 100%;
      }
    .table-striped > tbody > tr:nth-of-type(odd) > * {
      --bs-table-accent-bg: none;
    }
    .table-striped > tbody > tr > * {
      background-color: #f8f6f5;
    }
    button {
      display: flex;
      color: var(--primary-color);
      &:disabled {
        color: grey;
      }
    }
  }
  .text-truncate {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-mdc-card {
    box-shadow: none;
  }

  // DASHBOARD
  .custom-dashboard {
    padding: 1rem;
    .mat-mdc-card {
      box-shadow: none;
      border: 1px solid #dcdddd;
      background-color: #f8f6f6;
      display: flex;
      justify-content: center;
      min-height: 230px;
      border-radius: 2rem;
      @media screen and (max-width: 767px) {
        min-height: unset;
      }
      .mat-mdc-card-content {
        font-size: 24px;
        font-weight: 400;
        span {
          font-size: 80px;
          font-weight: 500;
          color: var(--primary-color);
        }
        small {
          font-size: 0.75rem;
          font-weight: 400;
          color: #c00f0c;
        }
      }
    }

    .co-btn-flat-primary {
      font-size: 14px;
      font-weight: 700;
      padding: 10px 26px;
    }
  }

  @media screen and (max-width: 767px) {
    .green-btn,
    .active-btn {
      width: 100%;
    }
    .table-custom {
      tbody {
        tr {
          border: #fff 1px solid;
        }
      }
      td {
        font-size: 12px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .mat-mdc-card {
      box-shadow: none !important;
    }

    table thead {
      display: none;
    }
    table,
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }
    table tr {
      margin-bottom: 1rem;
    }
    table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 1rem;
      text-align: left;
      font-weight: bold;
    }
  }
}
